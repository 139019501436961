@charset "UTF-8";
:root {
  --client-main-font-family:"Montserrat", sans-serif;
  --client-alt-font-family:"Lato", sans-serif;
  --client-color-default:#111111;
  --client-color-principal:#00843D;
  --client-color-secundaria:#1B1B1B;
  --client-color-alternativa:#E4F1E9;
  --client-color-extra:#9BCEAE;
  --client-color-titulos:#111111;
  --client-color-texto:#3D3935;
  --client-color-btn-border:#111111;
  --client-color-btn-border-hover:#111111;
  --client-color-btn-backg:#E4F1E9;
  --client-color-btn-backg-hover:#00843D;
  --client-color-btn-texto:#00843D;
  --client-color-btn-texto-hover:#E4F1E9;
  --client-color-background-modulo:#F5F5F4;
  --client-color-backg-modulo-destaque:#F7C7B6;
  --client-color-background:#ffffff;
  --client-color-menu-link:#111111;
  --client-color-menu-titulo:#111111;
  --client-color-btn-backg-secundario:#00843D;
  --client-color-btn-texto-secundario:#E4F1E9;
  --client-color-btn-backg-hover-secundario:#2C9C62;
  --client-color-btn-texto-hover-secundario:#ffffff;
  --client-body-backgroud-color: rgba(248, 249, 253, 0.42);
  --loader-color: #00a65a;
  --backgroud-flatpickr: transparent;
}

@keyframes shadow-pulse {
  0% {
    box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.2);
  }
  100% {
    box-shadow: 0 0 0 35px rgba(0, 0, 0, 0);
  }
}
.pulse-infinite {
  animation: shadow-pulse 1s infinite;
}

.pulse-3 {
  animation: shadow-pulse 1s 3;
}

.pulse-1 {
  animation: shadow-pulse 1s 1;
}

/* Absolute Center Spinner */
.loading {
  position: fixed;
  z-index: 999;
  height: 2em;
  width: 2em;
  overflow: show;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

/* Transparent Overlay */
.loading:before {
  content: "";
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(rgba(20, 20, 20, 0.8), rgba(0, 0, 0, 0.8));
  background: -webkit-radial-gradient(rgba(20, 20, 20, 0.8), rgba(0, 0, 0, 0.8));
}

/* :not(:required) hides these rules from IE9 and below */
.loading:not(:required) {
  /* hide "loading..." text */
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.loading:not(:required):after {
  content: "";
  display: block;
  font-size: 15px;
  width: 1em;
  height: 1em;
  margin-top: -0.5em;
  -webkit-animation: spinner 125ms infinite linear;
  -moz-animation: spinner 125ms infinite linear;
  -ms-animation: spinner 125ms infinite linear;
  -o-animation: spinner 125ms infinite linear;
  animation: spinner 125ms infinite linear;
  border-radius: 0.5em;
  -webkit-box-shadow: rgba(255, 255, 255, 0.75) 1.5em 0 0 0, rgba(255, 255, 255, 0.75) 1.1em 1.1em 0 0, rgba(255, 255, 255, 0.75) 0 1.5em 0 0, rgba(255, 255, 255, 0.75) -1.1em 1.1em 0 0, rgba(255, 255, 255, 0.75) -1.5em 0 0 0, rgba(255, 255, 255, 0.75) -1.1em -1.1em 0 0, rgba(255, 255, 255, 0.75) 0 -1.5em 0 0, rgba(255, 255, 255, 0.75) 1.1em -1.1em 0 0;
  box-shadow: rgba(255, 255, 255, 0.75) 1.5em 0 0 0, rgba(255, 255, 255, 0.75) 1.1em 1.1em 0 0, rgba(255, 255, 255, 0.75) 0 1.5em 0 0, rgba(255, 255, 255, 0.75) -1.1em 1.1em 0 0, rgba(255, 255, 255, 0.75) -1.5em 0 0 0, rgba(255, 255, 255, 0.75) -1.1em -1.1em 0 0, rgba(255, 255, 255, 0.75) 0 -1.5em 0 0, rgba(255, 255, 255, 0.75) 1.1em -1.1em 0 0;
}

/* Animation */
@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-moz-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-o-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.slim-loader {
  color: var(--loader-color);
  font-size: 90px;
  text-indent: -9999em;
  overflow: hidden;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  margin: 72px auto;
  position: relative;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load6 1.7s infinite ease, round 1.7s infinite ease;
  animation: load6 1.7s infinite ease, round 1.7s infinite ease;
}

@-webkit-keyframes load6 {
  0% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
  5%, 95% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
  10%, 59% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em, -0.173em -0.812em 0 -0.44em, -0.256em -0.789em 0 -0.46em, -0.297em -0.775em 0 -0.477em;
  }
  20% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em, -0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em, -0.749em -0.34em 0 -0.477em;
  }
  38% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em, -0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em, -0.82em -0.09em 0 -0.477em;
  }
  100% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
}
@keyframes load6 {
  0% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
  5%, 95% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
  10%, 59% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em, -0.173em -0.812em 0 -0.44em, -0.256em -0.789em 0 -0.46em, -0.297em -0.775em 0 -0.477em;
  }
  20% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em, -0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em, -0.749em -0.34em 0 -0.477em;
  }
  38% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em, -0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em, -0.82em -0.09em 0 -0.477em;
  }
  100% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
}
@-webkit-keyframes round {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes round {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.relative {
  position: relative;
}

.shadow-light {
  box-shadow: 5px 6px 7px 1px rgba(0, 0, 0, 0.05);
}

.card-box {
  background-color: #fff;
  padding: 1.5rem;
  box-shadow: 0 0.75rem 6rem rgba(56, 65, 74, 0.03);
  margin-bottom: 24px;
  border-radius: 0.25rem;
}

.btn-rounded {
  border-radius: 2em;
}

.force-text-danger {
  color: #ce0e28 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.alert-danger {
  color: #7d2c38 !important;
  background-color: #fcdde2 !important;
  border-color: #fbcfd6 !important;
}

.not-allowed {
  cursor: not-allowed;
}

.alert-info-new {
  color: #296775 !important;
  background-color: #dcf4f9 !important;
  border-color: #ceeff7 !important;
}

.alert-warning-new {
  color: #806027;
  background-color: #fdf1db;
  border-color: #fdebcd;
}

.alert-new {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.font-italic {
  font-style: italic !important;
}

.card-create-holiday {
  border: 1px solid #e9ebef;
  border-radius: 4px;
}

#show-holiday .modal-body {
  margin: 0 !important;
}

#show-my-holiday .modal-body {
  margin: 0 !important;
}

.dashboard-implementer .btn {
  border-radius: 0 !important;
  color: white !important;
}

.box.box-dark {
  border-top-color: #333;
}

.bg-primary {
  background-color: #38414a !important;
}

.card-implementer {
  min-height: 85px !important;
  color: #3fa65b;
  padding-top: 1rem !important;
  font-size: 4em;
}
.card-implementer:hover {
  color: rgba(36, 138, 63, 0.93);
}

.fa-1-3px {
  font-size: 1.3em;
}

.timeline-item .box-header > .box-tools {
  top: unset;
  bottom: 26px;
}
.timeline-item .time-label {
  font-size: 12px;
}
.timeline-item .small {
  border-bottom: 1px solid #f1f1f1;
  color: #797979;
}

.header-large {
  padding: 15px 0 0 0 !important;
}

.form-group.required label:not(:empty)::after {
  content: " *";
  color: #ff0000;
}

.is-required::after {
  content: " *";
  color: #ff0000;
}

.image-employe > img {
  position: absolute;
  width: 152px;
  height: 149px;
  right: 984px;
  top: 59px;
}
.image-employe > img img {
  background: "";
  border: 1px solid #000000;
  box-sizing: border-box;
  border-radius: 100px;
}

.scroll-modal-report {
  display: block;
  height: 78vh;
  overflow-y: scroll;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 3px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #aae4c9;
  border-radius: 2px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #aae4c9;
}

.help-block-report-preview-client {
  display: block;
  margin-top: 5px;
  margin-bottom: 10px;
  color: #838586;
  font-style: italic;
  font-size: 10pt;
}

.justify-client {
  position: relative;
}

.d-grid {
  display: grid;
}

.btn-wt {
  background-color: #00a65a;
  color: #333;
  border-color: #00a65a;
}

.btn-dark {
  background-color: #191a19;
  border-color: #191a19;
  color: #fff !important;
  text-decoration: none;
  background: #191a19;
  border-radius: 5px;
  display: inline-block;
  border: none;
  transition: all 0.4s ease 0s;
}

.btn-dark:hover {
  color: #ffffff;
  background: #434343;
  -webkit-box-shadow: 0px 5px 40px -10px rgba(0, 0, 0, 0.57);
  -moz-box-shadow: 0px 5px 40px -10px rgba(0, 0, 0, 0.57);
  box-shadow: 5px 40px -10px rgba(0, 0, 0, 0.57);
  transition: all 0.4s ease 0s;
}

.btn-remove {
  background-color: rgba(255, 255, 255, 0);
  border-color: rgba(255, 255, 255, 0);
  color: red;
  text-transform: uppercase;
  text-decoration: none;
  background: rgba(255, 255, 255, 0);
  border-radius: 5px;
  display: inline-block;
  border: none;
  transition: all 0.4s ease 0s;
}

.btn-remove:hover {
  color: #fff;
  background: rgb(196, 28, 28);
  -webkit-box-shadow: 0px 5px 40px -10px rgba(0, 0, 0, 0.57);
  -moz-box-shadow: 0px 5px 40px -10px rgba(0, 0, 0, 0.57);
  box-shadow: 5px 40px -10px rgba(0, 0, 0, 0.57);
  transition: all 0.4s ease 0s;
}

.notAccess {
  color: red;
}

.imgBox {
  height: 123px;
}

.form-control {
  border-radius: 5px;
  min-height: 40px;
}

.form-control:focus {
  border-color: #38414a;
}

.h2Title {
  margin-top: 32px;
}

.zoom-datatable {
  max-height: 50px;
  width: 50px;
  max-width: 50px;
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -ms-transition: all 1s ease;
  transition: transform 2s, filter 1.5s ease-in-out;
}

.label.orange {
  color: #fff;
  background-color: #FF5722 !important;
}

.ko_percente.red {
  color: red;
}

.ko_percente.orange {
  color: #FF5722;
}

.ko_percente.blue {
  color: blue;
}

.ko_percente.green {
  color: green;
}

.capitalize {
  text-transform: capitalize;
}

.search-with-label {
  padding-top: 2.5rem !important;
  margin-left: 15px !important;
}

.link_client_ok {
  cursor: pointer;
}

.modal-header {
  border-bottom-color: #f4f4f4;
  background: #38414A;
  color: #fff;
}

.switch-button {
  margin: 10px 0;
}

.bottom-message {
  margin-top: 20px;
  font-size: 125%;
}

.switch-button-control {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.switch-button-control .switch-button {
  height: 1.6em;
  width: calc(1.6em * 2);
  border: 2px solid var(--color);
  box-shadow: inset 0px 0px 2px 0px rgba(0, 0, 0, 0.33);
  border-radius: 1.6em;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
}
.switch-button-control .switch-button .button {
  height: calc(1.6em - 2 * 2px);
  width: calc(1.6em - 2 * 2px);
  border: 2px solid var(--color);
  border-radius: calc(1.6em - 2 * 2px);
  background: var(--color);
  transition: all 0.3s ease-in-out;
}
.switch-button-control .switch-button.enabled {
  background-color: var(--color);
  box-shadow: none;
}
.switch-button-control .switch-button.enabled .button {
  background: white;
  transform: translateX(calc(calc(1.6em - 2 * 2px) + 2 * 2px));
}
.switch-button-control .switch-button-label {
  margin-left: 10px;
  margin-right: 10px;
}

@media (max-width: 768px) {
  .col-xs-0 {
    display: none;
  }
  .pull-right-mobile {
    float: right !important;
  }
  .btn-xs-block {
    display: block;
    width: 100%;
  }
  .mt-xs-2 {
    margin-top: 0.5rem !important;
  }
}
@media (min-width: 768px) {
  .zoom-datatable:hover {
    transform: scale(10);
    width: 300px;
    height: 300px;
  }
  .xl-modal {
    width: 1280px !important;
  }
  .btn-inline-desktop {
    padding-top: 29px;
  }
}
fieldset[disabled] .multiselect {
  pointer-events: none;
}

.multiselect__spinner {
  position: absolute;
  right: 1px;
  top: 1px;
  width: 48px;
  height: 35px;
  background: #fff;
  display: block;
}

.multiselect__spinner:after,
.multiselect__spinner:before {
  position: absolute;
  content: "";
  top: 50%;
  left: 50%;
  margin: -8px 0 0 -8px;
  width: 16px;
  height: 16px;
  border-radius: 100%;
  border-color: #03A9F4 transparent transparent;
  border-style: solid;
  border-width: 2px;
  box-shadow: 0 0 0 1px transparent;
}

.multiselect__spinner:before {
  animation: a 2.4s cubic-bezier(0.41, 0.26, 0.2, 0.62);
  animation-iteration-count: infinite;
}

.multiselect__spinner:after {
  animation: a 2.4s cubic-bezier(0.51, 0.09, 0.21, 0.8);
  animation-iteration-count: infinite;
}

.multiselect__loading-enter-active,
.multiselect__loading-leave-active {
  transition: opacity 0.4s ease-in-out;
  opacity: 1;
}

.multiselect__loading-enter,
.multiselect__loading-leave-active {
  opacity: 0;
}

.multiselect,
.multiselect__input,
.multiselect__single {
  font-family: inherit;
  font-size: 16px;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
}

.multiselect {
  box-sizing: content-box;
  display: block;
  position: relative;
  width: 100%;
  min-height: 40px;
  text-align: left;
  color: #35495e;
}

.multiselect * {
  box-sizing: border-box;
}

.multiselect:focus {
  outline: none;
}

.multiselect--disabled {
  opacity: 0.6;
}

.multiselect--active {
  z-index: 1;
}

.multiselect--active:not(.multiselect--above) .multiselect__current,
.multiselect--active:not(.multiselect--above) .multiselect__input,
.multiselect--active:not(.multiselect--above) .multiselect__tags {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.multiselect--active .multiselect__select {
  transform: rotate(180deg);
}

.multiselect--above.multiselect--active .multiselect__current,
.multiselect--above.multiselect--active .multiselect__input,
.multiselect--above.multiselect--active .multiselect__tags {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.multiselect__input,
.multiselect__single {
  position: relative;
  display: inline-block;
  min-height: 20px;
  line-height: 20px;
  border: none;
  border-radius: 5px;
  background: #fff;
  padding: 0 0 0 5px;
  width: 100%;
  transition: border 0.1s ease;
  box-sizing: border-box;
  margin-bottom: 8px;
  vertical-align: top;
}

.multiselect__input::-webkit-input-placeholder {
  color: #35495e;
}

.multiselect__input:-ms-input-placeholder {
  color: #35495e;
}

.multiselect__input::placeholder {
  color: #35495e;
}

.multiselect__tag ~ .multiselect__input,
.multiselect__tag ~ .multiselect__single {
  width: auto;
}

.multiselect__input:hover,
.multiselect__single:hover {
  border-color: #cfcfcf;
}

.multiselect__input:focus,
.multiselect__single:focus {
  border-color: #a8a8a8;
  outline: none;
}

.multiselect__single {
  padding-left: 5px;
  margin-bottom: 8px;
}

.multiselect__tags-wrap {
  display: inline;
}

.multiselect__tags {
  min-height: 40px;
  display: block;
  padding: 8px 40px 0 8px;
  border-radius: 5px;
  border: 1px solid #e8e8e8;
  background: #fff;
  font-size: 14px;
}

.multiselect__tag {
  position: relative;
  display: inline-block;
  padding: 4px 26px 4px 10px;
  border-radius: 5px;
  margin-right: 10px;
  color: #fff;
  line-height: 1;
  background: #03A9F4;
  margin-bottom: 5px;
  white-space: nowrap;
  overflow: hidden;
  max-width: 100%;
  text-overflow: ellipsis;
}

.multiselect__tag-icon {
  cursor: pointer;
  margin-left: 7px;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  font-weight: 700;
  font-style: normal;
  width: 22px;
  text-align: center;
  line-height: 22px;
  transition: all 0.2s ease;
  border-radius: 5px;
}

.multiselect__tag-icon:after {
  content: "×";
  color: #fff;
  font-size: 14px;
}

.multiselect__tag-icon:focus,
.multiselect__tag-icon:hover {
  background: #565454;
}

.multiselect__tag-icon:focus:after,
.multiselect__tag-icon:hover:after {
  color: #fff;
}

.multiselect__current {
  min-height: 40px;
  overflow: hidden;
  padding: 8px 12px 0;
  padding-right: 30px;
  white-space: nowrap;
  border-radius: 5px;
  border: 1px solid #e8e8e8;
}

.multiselect__current,
.multiselect__select {
  line-height: 16px;
  box-sizing: border-box;
  display: block;
  margin: 0;
  text-decoration: none;
  cursor: pointer;
}

.multiselect__select {
  position: absolute;
  width: 40px;
  height: 38px;
  right: 1px;
  top: 1px;
  padding: 4px 8px;
  text-align: center;
  transition: transform 0.2s ease;
}

.multiselect__select:before {
  position: relative;
  right: 0;
  top: 65%;
  color: #999;
  margin-top: 4px;
  border-style: solid;
  border-width: 5px 5px 0;
  border-color: #999 transparent transparent;
  content: "";
}

.multiselect__placeholder {
  color: #adadad;
  display: inline-block;
  margin-bottom: 10px;
  padding-top: 2px;
}

.multiselect--active .multiselect__placeholder {
  display: none;
}

.multiselect__content-wrapper {
  position: absolute;
  display: block;
  background: #fff;
  width: 100%;
  max-height: 240px;
  overflow: auto;
  border: 1px solid #e8e8e8;
  border-top: none;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  z-index: 1;
  -webkit-overflow-scrolling: touch;
}

.multiselect__content {
  list-style: none;
  display: inline-block;
  padding: 0;
  margin: 0;
  min-width: 100%;
  vertical-align: top;
}

.multiselect--above .multiselect__content-wrapper {
  bottom: 100%;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom: none;
  border-top: 1px solid #e8e8e8;
}

.multiselect__content::webkit-scrollbar {
  display: none;
}

.multiselect__element {
  display: block;
}

.multiselect__option {
  display: block;
  padding: 12px;
  min-height: 40px;
  line-height: 16px;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  position: relative;
  cursor: pointer;
  white-space: nowrap;
}

.multiselect__option:after {
  top: 0;
  right: 0;
  position: absolute;
  line-height: 40px;
  padding-right: 12px;
  padding-left: 20px;
  font-size: 13px;
}

.multiselect__option--highlight {
  background: #03A9F4;
  outline: none;
  color: #fff;
}

.multiselect__option--highlight:after {
  content: attr(data-select);
  background: #03A9F4;
  color: #fff;
}

.multiselect__option--selected {
  background: #f3f3f3;
  color: #35495e;
  font-weight: 700;
}

.multiselect__option--selected:after {
  content: attr(data-selected);
  color: silver;
}

.multiselect__option--selected.multiselect__option--highlight {
  background: #ff6a6a;
  color: #fff;
}

.multiselect__option--selected.multiselect__option--highlight:after {
  background: #ff6a6a;
  content: attr(data-deselect);
  color: #fff;
}

.multiselect--disabled {
  background: #ededed;
  pointer-events: none;
}

.multiselect--disabled .multiselect__current,
.multiselect--disabled .multiselect__select,
.multiselect__option--disabled {
  background: #ededed;
  color: #a6a6a6;
}

.multiselect__option--disabled {
  cursor: text;
  pointer-events: none;
}

.multiselect__option--group {
  background: #ededed;
  color: #35495e;
}

.multiselect__option--group.multiselect__option--highlight {
  background: #35495e;
  color: #fff;
}

.multiselect__option--group.multiselect__option--highlight:after {
  background: #35495e;
}

.multiselect__option--disabled.multiselect__option--highlight {
  background: #dedede;
}

.multiselect__option--group-selected.multiselect__option--highlight {
  background: #ff6a6a;
  color: #fff;
}

.multiselect__option--group-selected.multiselect__option--highlight:after {
  background: #ff6a6a;
  content: attr(data-deselect);
  color: #fff;
}

.multiselect-enter-active,
.multiselect-leave-active {
  transition: all 0.15s ease;
}

.multiselect-enter,
.multiselect-leave-active {
  opacity: 0;
}

.multiselect__strong {
  margin-bottom: 8px;
  line-height: 20px;
  display: inline-block;
  vertical-align: top;
}

[dir=rtl] .multiselect {
  text-align: right;
}

[dir=rtl] .multiselect__select {
  right: auto;
  left: 1px;
}

[dir=rtl] .multiselect__tags {
  padding: 8px 8px 0 40px;
}

[dir=rtl] .multiselect__content {
  text-align: right;
}

[dir=rtl] .multiselect__option:after {
  right: auto;
  left: 0;
}

[dir=rtl] .multiselect__clear {
  right: auto;
  left: 12px;
}

[dir=rtl] .multiselect__spinner {
  right: auto;
  left: 1px;
}

@keyframes a {
  0% {
    transform: rotate(0);
  }
  to {
    transform: rotate(2turn);
  }
}
.modal-header {
  border-radius: 4px 4px 0px 0px;
}

.modal-footer {
  border-radius: 0px 0px 4px 4px;
}

.modal-content {
  border-radius: 5px;
}

@media (min-width: 992px) {
  .modal-xl {
    max-width: 800px;
  }
}
@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}
.main-footer {
  background-color: #f4f5f7;
}

.copyright {
  font-size: 9px;
}

.empty-result {
  margin: auto;
  width: 50%;
  padding: 10px;
  text-align: center;
}

.total-records-datatable {
  text-align: center;
  padding: 5px;
}

.bg-white {
  background-color: #fff;
}

.text-align-center {
  text-align: center;
}

.radio-with-select {
  margin-top: 25px;
}

.navbar-dark {
  background-color: #202123;
}

[data-type=alter_status_report]:hover {
  color: #fdfdfd;
  background-color: #48494a;
}

.pt-23 {
  padding-top: 23px !important;
}

.pointer-circle {
  cursor: pointer !important;
}

.block-pointer {
  cursor: not-allowed !important;
  pointer-events: all !important;
}

.form-control-multi-select {
  height: 42px;
}

.has-error-new .multiselect__tags {
  border: 1px solid red !important;
}

.force-font-size {
  font-size: 14px;
}

@media (max-width: 768px) {
  .layout-black .header-slide-show {
    top: 5px;
    width: 100%;
    background-color: #fdfdfd;
    color: #333;
  }
  .layout-black .header-slide-show .fas, .layout-black .header-slide-show .far {
    color: #48494a;
  }
  .layout-black .header-slide-show .fas:hover, .layout-black .header-slide-show .far:hover {
    color: #32373c;
  }
  .layout-black .content-slide-show {
    background-color: #fdfdfd;
  }
}
@media (min-width: 768px) {
  .layout-black .header-slide-show {
    position: fixed;
    z-index: 999;
    top: 0px;
    display: block;
    width: 100%;
    height: 158px;
    background-color: #fdfdfd;
    color: #333;
    border-bottom: 2px solid #ecf0f5;
  }
  .layout-black .header-slide-show .fas, .layout-black .header-slide-show .far {
    color: #48494a;
  }
  .layout-black .header-slide-show .fas:hover, .layout-black .header-slide-show .far:hover {
    color: #32373c;
  }
  .layout-black .content-slide-show {
    background-color: #fdfdfd;
    padding-top: 158px;
  }
}
.layout-black .lightgray {
  color: lightgrey !important;
  cursor: not-allowed;
  pointer-events: all !important;
}
.layout-black .numberCircle {
  border-radius: 15%;
  width: 36px;
  height: 36px;
  padding: 8px;
  background: #fff;
  border: 2px solid #666;
  color: #666;
  text-align: center;
  font: 23px Arial, sans-serif;
}
.layout-black .footer-black {
  background-color: #fdfdfd;
  color: white;
}
.layout-black .pagination-slide-show {
  position: absolute;
  padding: 20px;
  padding-top: 50px;
}
.layout-black .slide-show-header {
  background-color: #fdfdfd;
  height: 54px;
  color: #fff;
}
.layout-black .box-slide-show {
  background: #1d1d1d;
  color: #fff;
}
.layout-black .page-link:hover {
  border-color: #48494a;
  background-color: #48494a;
}
.layout-black .pagination > .active > span {
  background-color: #48494a;
  border-color: #48494a;
}
.layout-black .pagination > li > a:hover {
  color: #333;
}
.layout-black [data-type=alter_status_report]:hover {
  color: white;
  background-color: #48494a;
}
.layout-black .total_register {
  border-radius: 6%;
  /* width: 36px; */
  /* height: 36px; */
  padding: 7px;
  background: #fff;
  border: 1px solid #666;
  /* color: #666; */
  text-align: center;
  font: 11px Arial, sans-serif;
}

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

.col {
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -ms-flex-positive: 1;
  flex-grow: 1;
  max-width: 100%;
}

.col-auto {
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

.col-1 {
  -ms-flex: 0 0 8.333333%;
  flex: 0 0 8.333333%;
  max-width: 8.333333%;
}

.col-2 {
  -ms-flex: 0 0 16.666667%;
  flex: 0 0 16.666667%;
  max-width: 16.666667%;
}

.col-3 {
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  -ms-flex: 0 0 33.333333%;
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
}

.col-5 {
  -ms-flex: 0 0 41.666667%;
  flex: 0 0 41.666667%;
  max-width: 41.666667%;
}

.col-6 {
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  -ms-flex: 0 0 58.333333%;
  flex: 0 0 58.333333%;
  max-width: 58.333333%;
}

.col-8 {
  -ms-flex: 0 0 66.666667%;
  flex: 0 0 66.666667%;
  max-width: 66.666667%;
}

.col-9 {
  -ms-flex: 0 0 75%;
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  -ms-flex: 0 0 83.333333%;
  flex: 0 0 83.333333%;
  max-width: 83.333333%;
}

.col-11 {
  -ms-flex: 0 0 91.666667%;
  flex: 0 0 91.666667%;
  max-width: 91.666667%;
}

.col-12 {
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
}

.limited-text {
  white-space: nowrap;
  width: 340px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.dropdown-menu-clients {
  min-width: 200px;
  max-height: 350px;
  overflow: auto;
}

.dataTables_wrapper .dataTables_processing {
  margin-top: 45px;
}

.uppercase {
  text-transform: uppercase;
}

.info-small {
  font-size: 10pt;
}

.btn-input-group {
  background-color: #e8e8e8 !important;
  border-top-right-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
}

/* The container */
.container-checkbox {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border-radius: 1px;
}

/* Hide the browser's default checkbox */
.container-checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
}

/* On mouse-over, add a grey background color */
.container-checkbox:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.container-checkbox input:checked ~ .checkmark {
  background-color: #2196F3;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container-checkbox input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container-checkbox .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.input-group .form-control {
  z-index: 0 !important;
}

.blr-5 {
  border-top-left-radius: 5px !important;
  border-bottom-left-radius: 5px !important;
}

.btn-lg-control {
  min-height: 40px;
  text-align: center;
  vertical-align: middle;
  display: inline-block;
  line-height: 28px;
}

.fix-height-responsive {
  overflow-x: auto;
  min-height: 390px;
}

.float-right {
  float: right !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.align-self-center {
  -ms-flex-item-align: center !important;
  align-self: center !important;
}

.break-words {
  overflow-wrap: break-word;
}

.w-full {
  width: 100%;
}

.inline-flex {
  display: inline-flex;
}

.absolute {
  position: absolute;
}

.top-0 {
  top: 0px;
}

.right-0 {
  right: 0px;
}

.bottom-0 {
  bottom: 0px;
}

.left-0 {
  left: 0px;
}

.items-center {
  align-items: center;
}

.rounded-md {
  border-radius: 0.375rem;
}

.pl-10 {
  padding-left: 2.5rem;
}

.top-5 {
  top: 5px;
}

.col-auto,
.col-xl,
.col-xl-auto,
.col-xl-12,
.col-xl-11,
.col-xl-10,
.col-xl-9,
.col-xl-8,
.col-xl-7,
.col-xl-6,
.col-xl-5,
.col-xl-4,
.col-xl-3,
.col-xl-2,
.col-xl-1 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-xl-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .col-xl-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .col-xl-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 49%;
  }
  .col-xl-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .col-xl-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .col-xl-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xl-first {
    order: -1;
  }
  .order-xl-last {
    order: 13;
  }
  .order-xl-0 {
    order: 0;
  }
  .order-xl-1 {
    order: 1;
  }
  .order-xl-2 {
    order: 2;
  }
  .order-xl-3 {
    order: 3;
  }
  .order-xl-4 {
    order: 4;
  }
  .order-xl-5 {
    order: 5;
  }
  .order-xl-6 {
    order: 6;
  }
  .order-xl-7 {
    order: 7;
  }
  .order-xl-8 {
    order: 8;
  }
  .order-xl-9 {
    order: 9;
  }
  .order-xl-10 {
    order: 10;
  }
  .order-xl-11 {
    order: 11;
  }
  .order-xl-12 {
    order: 12;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.3333333333%;
  }
  .offset-xl-2 {
    margin-left: 16.6666666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.3333333333%;
  }
  .offset-xl-5 {
    margin-left: 41.6666666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.3333333333%;
  }
  .offset-xl-8 {
    margin-left: 66.6666666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.3333333333%;
  }
  .offset-xl-11 {
    margin-left: 91.6666666667%;
  }
}
.flatpickr-div .form-control[readonly] {
  background-color: var(--backgroud-flatpickr);
  cursor: pointer;
}

.h-screen {
  height: 100vh;
}

.h-full {
  height: 100%;
}

.skin-green .sidebar-menu > li:hover > a, .skin-green .sidebar-menu > li.active > a, .skin-green .sidebar-menu > li.menu-open > a {
  background: white;
}

.sidebar-mini:not(.sidebar-mini-expand-feature).sidebar-collapse .sidebar-menu > li:hover > a > span:not(.pull-right), .sidebar-mini:not(.sidebar-mini-expand-feature).sidebar-collapse .sidebar-menu > li:hover > .treeview-menu {
  min-width: 230px;
}

.checkbox > .label {
  color: #333;
}

.justify-content-center {
  -ms-flex-pack: center;
  justify-content: center;
}

.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
}

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #3490dc;
  background-color: #3490dc;
}

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e");
}

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(52, 144, 220, 0.5);
}

.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(52, 144, 220, 0.5);
}

@-webkit-keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}
@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}
.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  -webkit-animation: spinner-border 0.75s linear infinite;
  animation: spinner-border 0.75s linear infinite;
}

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.text-secondary {
  color: #6c757d !important;
}

a.text-secondary:hover,
a.text-secondary:focus {
  color: #494f54 !important;
}

.v-breadcrumb {
  padding: 8px 15px;
  margin: 0;
  list-style: none;
}

.v-breadcrumb > li {
  display: inline-block;
}

.v-breadcrumb > li {
  color: #b3b3b5;
  text-decoration: none;
  display: inline-block;
}

.v-breadcrumb > li > a {
  color: #b3b3b5;
  text-decoration: none;
  display: inline-block;
}

.v-breadcrumb > li + li:before {
  padding: 0 5px;
  color: #ccc;
  content: "> ";
}

.vertical-tabs-wrapper {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  width: 200px;
}

.vertical-tab {
  background-color: #F5F6F8;
  color: #6C757D;
  border-radius: 5px;
  padding: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
}

.vertical-tab.active {
  background-color: #089B17;
  color: #FFF;
  font-weight: bold;
}

.vertical-tab:hover:not(.active) {
  background-color: #e5e6e8;
}

.horizontal-tabs-wrapper {
  display: flex;
  gap: 2rem;
}

.horizontal-tab {
  position: relative;
  cursor: pointer;
  height: fit-content;
}

.horizontal-tab:before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 0;
  height: 2px;
  font-size: 17px;
  transition: width 0.3s ease-in-out;
  background-color: #089B17;
}

.horizontal-tab.active:before {
  width: 100%;
  transition: width 0.3s ease-in-out;
}

.v-tab-content {
  transition: all 0.5s ease-in-out;
  transform: translateX(75px);
  position: absolute;
  opacity: 0;
}

.v-tab-content.active {
  transform: translateX(0);
  opacity: 100%;
}

.text-end {
  text-align: end;
}